// https://gist.github.com/joejordanbrown/670a31c9b3406de7c36cf618eb305c41
/* -- Material Design Table style -------------- */

// Variables
// ---------------------
$table-header-font-weight: 800;
$table-header-font-color: var(--gray2);

$table-cell-padding: 1rem;
$table-condensed-cell-padding: $table-cell-padding/2;

$table-bg: white;
$table-bg-accent: var(--gray);
$table-bg-hover: var(--gray);
$table-bg-active: $table-bg-hover;
$table-border-color: var(--gray);

// Mixins
// -----------------
@mixin transition($transition) {
    // -webkit-transition: $transition;
    // -o-transition: $transition;
    // transition: $transition;
}

@mixin shadow-z-1 {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}

// Tables
//
// -----------------

// Baseline styles
.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: $table-bg;
    font-size: 0.8rem;
    border-collapse: collapse;
    table-layout: fixed;
    > thead,
    > tbody,
    > tfoot {
        > tr {
            @include transition(all 0.3s ease);
            > th,
            > td {
                text-align: left;
                padding: $table-cell-padding;
                vertical-align: top;
                border-top: 0;
                line-height: 140%;
                @include transition(all 0.3s ease);
            }
        }
    }
    > thead > tr > th {
        font-weight: $table-header-font-weight;
        color: $table-header-font-color;
        vertical-align: bottom;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    > caption + thead,
    > colgroup + thead,
    > thead:first-child {
        > tr:first-child {
            > th,
            > td {
                border-top: 0;
            }
        }
    }
    > tbody + tbody {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
    }

    // Nesting
    .table {
        background-color: $table-bg;
    }

    // Remove border
    .no-border {
        border: 0;
    }
}

.table > thead > tr > th {
    user-select: none;
    cursor: context;
}

// Condensed table w/ half padding
.table-condensed {
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                padding: $table-condensed-cell-padding;
            }
        }
    }
}

// Bordered version
//
// Add horizontal borders between columns.
.table-bordered {
    border: 0;
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                border: 0;
                border-bottom: 1px solid $table-border-color;
            }
        }
    }
    > thead > tr {
        > th,
        > td {
            border-bottom-width: 2px;
        }
    }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)
.table-striped {
    > tbody > tr:nth-child(odd) {
        > td,
        > th {
            background-color: $table-bg-accent;
        }
    }
}

// Hover effect
//
.table-hover {
    > tbody > tr:hover:not(.no-hover) {
        cursor: pointer;
        > td,
        > th {
            background-color: $table-bg-hover;
        }
    }
}

// Responsive tables (vertical)
//
// Wrap your tables in `.table-responsive-vertical` and we'll make them mobile friendly
// by vertical table-cell display. Only applies <768px. Everything above that will display normally.
// For correct display you must add 'data-title' to each 'td'
.table-responsive-vertical {
    @media screen and (max-width: 768px) {
        // Tighten up spacing
        > .table {
            margin-bottom: 0;
            background-color: transparent;
            > thead,
            > tfoot {
                display: none;
            }

            > tbody {
                display: block;

                > tr {
                    display: block;
                    border: 1px solid $table-border-color;
                    border-radius: 2px;
                    margin-bottom: $table-cell-padding;

                    > td {
                        background-color: $table-bg;
                        display: block;
                        vertical-align: middle;
                        text-align: right;
                    }
                    > td[data-title]:before {
                        content: attr(data-title);
                        float: left;
                        font-size: inherit;
                        font-weight: $table-header-font-weight;
                        color: $table-header-font-color;
                    }
                }
            }
        }

        // Special overrides for shadows
        &.shadow-z-1 {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            > .table > tbody > tr {
                border: none;
                @include shadow-z-1();
            }
        }

        // Special overrides for the bordered tables
        > .table-bordered {
            border: 0;

            // Nuke the appropriate borders so that the parent can handle them
            > tbody {
                > tr {
                    > td {
                        border: 0;
                        border-bottom: 1px solid $table-border-color;
                    }
                    > td:last-child {
                        border-bottom: 0;
                    }
                }
            }
        }

        // Special overrides for the striped tables
        > .table-striped {
            > tbody > tr > td,
            > tbody > tr:nth-child(odd) {
                background-color: $table-bg;
            }
            > tbody > tr > td:nth-child(odd) {
                background-color: $table-bg-accent;
            }
        }

        // Special overrides for hover tables
        > .table-hover {
            > tbody {
                > tr:hover > td,
                > tr:hover {
                    background-color: $table-bg;
                }
                > tr:not(.no-hover) > td:hover {
                    background-color: $table-bg-hover;
                }
            }
        }
    }
}

// CSS/LESS Color variations
//
// --------------------------------

.table-striped.table-mc-red > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-red > tbody > tr:nth-child(odd) > th {
    background-color: #fde0dc;
}
.table-hover.table-mc-red > tbody > tr:hover > td,
.table-hover.table-mc-red > tbody > tr:hover > th {
    background-color: #f9bdbb;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-red > tbody > tr > td,
    .table-responsive-vertical
        .table-striped.table-mc-red
        > tbody
        > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-striped.table-mc-red
        > tbody
        > tr
        > td:nth-child(odd) {
        background-color: #fde0dc;
    }
    .table-responsive-vertical
        .table-hover.table-mc-red
        > tbody
        > tr:hover
        > td,
    .table-responsive-vertical .table-hover.table-mc-red > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-hover.table-mc-red
        > tbody
        > tr
        > td:hover {
        background-color: #f9bdbb;
    }
}
.table-striped.table-mc-pink > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-pink > tbody > tr:nth-child(odd) > th {
    background-color: #fce4ec;
}
.table-hover.table-mc-pink > tbody > tr:hover > td,
.table-hover.table-mc-pink > tbody > tr:hover > th {
    background-color: #f8bbd0;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-pink > tbody > tr > td,
    .table-responsive-vertical
        .table-striped.table-mc-pink
        > tbody
        > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-striped.table-mc-pink
        > tbody
        > tr
        > td:nth-child(odd) {
        background-color: #fce4ec;
    }
    .table-responsive-vertical
        .table-hover.table-mc-pink
        > tbody
        > tr:hover
        > td,
    .table-responsive-vertical .table-hover.table-mc-pink > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-hover.table-mc-pink
        > tbody
        > tr
        > td:hover {
        background-color: #f8bbd0;
    }
}
.table-striped.table-mc-purple > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-purple > tbody > tr:nth-child(odd) > th {
    background-color: #f3e5f5;
}
.table-hover.table-mc-purple > tbody > tr:hover > td,
.table-hover.table-mc-purple > tbody > tr:hover > th {
    background-color: #e1bee7;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-purple > tbody > tr > td,
    .table-responsive-vertical
        .table-striped.table-mc-purple
        > tbody
        > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-striped.table-mc-purple
        > tbody
        > tr
        > td:nth-child(odd) {
        background-color: #f3e5f5;
    }
    .table-responsive-vertical
        .table-hover.table-mc-purple
        > tbody
        > tr:hover
        > td,
    .table-responsive-vertical .table-hover.table-mc-purple > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-hover.table-mc-purple
        > tbody
        > tr
        > td:hover {
        background-color: #e1bee7;
    }
}
.table-striped.table-mc-deep-purple > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-deep-purple > tbody > tr:nth-child(odd) > th {
    background-color: #ede7f6;
}
.table-hover.table-mc-deep-purple > tbody > tr:hover > td,
.table-hover.table-mc-deep-purple > tbody > tr:hover > th {
    background-color: #d1c4e9;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical
        .table-striped.table-mc-deep-purple
        > tbody
        > tr
        > td,
    .table-responsive-vertical
        .table-striped.table-mc-deep-purple
        > tbody
        > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-striped.table-mc-deep-purple
        > tbody
        > tr
        > td:nth-child(odd) {
        background-color: #ede7f6;
    }
    .table-responsive-vertical
        .table-hover.table-mc-deep-purple
        > tbody
        > tr:hover
        > td,
    .table-responsive-vertical
        .table-hover.table-mc-deep-purple
        > tbody
        > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-hover.table-mc-deep-purple
        > tbody
        > tr
        > td:hover {
        background-color: #d1c4e9;
    }
}
.table-striped.table-mc-indigo > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-indigo > tbody > tr:nth-child(odd) > th {
    background-color: #e8eaf6;
}
.table-hover.table-mc-indigo > tbody > tr:hover > td,
.table-hover.table-mc-indigo > tbody > tr:hover > th {
    background-color: #c5cae9;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-indigo > tbody > tr > td,
    .table-responsive-vertical
        .table-striped.table-mc-indigo
        > tbody
        > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-striped.table-mc-indigo
        > tbody
        > tr
        > td:nth-child(odd) {
        background-color: #e8eaf6;
    }
    .table-responsive-vertical
        .table-hover.table-mc-indigo
        > tbody
        > tr:hover
        > td,
    .table-responsive-vertical .table-hover.table-mc-indigo > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-hover.table-mc-indigo
        > tbody
        > tr
        > td:hover {
        background-color: #c5cae9;
    }
}
.table-striped.table-mc-blue > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-blue > tbody > tr:nth-child(odd) > th {
    background-color: #e7e9fd;
}
.table-hover.table-mc-blue > tbody > tr:hover > td,
.table-hover.table-mc-blue > tbody > tr:hover > th {
    background-color: #d0d9ff;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-blue > tbody > tr > td,
    .table-responsive-vertical
        .table-striped.table-mc-blue
        > tbody
        > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-striped.table-mc-blue
        > tbody
        > tr
        > td:nth-child(odd) {
        background-color: #e7e9fd;
    }
    .table-responsive-vertical
        .table-hover.table-mc-blue
        > tbody
        > tr:hover
        > td,
    .table-responsive-vertical .table-hover.table-mc-blue > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-hover.table-mc-blue
        > tbody
        > tr
        > td:hover {
        background-color: #d0d9ff;
    }
}
.table-striped.table-mc-light-blue > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-light-blue > tbody > tr:nth-child(odd) > th {
    background-color: #e1f5fe;
}
.table-hover.table-mc-light-blue > tbody > tr:hover > td,
.table-hover.table-mc-light-blue > tbody > tr:hover > th {
    background-color: #b3e5fc;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical
        .table-striped.table-mc-light-blue
        > tbody
        > tr
        > td,
    .table-responsive-vertical
        .table-striped.table-mc-light-blue
        > tbody
        > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-striped.table-mc-light-blue
        > tbody
        > tr
        > td:nth-child(odd) {
        background-color: #e1f5fe;
    }
    .table-responsive-vertical
        .table-hover.table-mc-light-blue
        > tbody
        > tr:hover
        > td,
    .table-responsive-vertical
        .table-hover.table-mc-light-blue
        > tbody
        > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-hover.table-mc-light-blue
        > tbody
        > tr
        > td:hover {
        background-color: #b3e5fc;
    }
}
.table-striped.table-mc-cyan > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-cyan > tbody > tr:nth-child(odd) > th {
    background-color: #e0f7fa;
}
.table-hover.table-mc-cyan > tbody > tr:hover > td,
.table-hover.table-mc-cyan > tbody > tr:hover > th {
    background-color: #b2ebf2;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-cyan > tbody > tr > td,
    .table-responsive-vertical
        .table-striped.table-mc-cyan
        > tbody
        > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-striped.table-mc-cyan
        > tbody
        > tr
        > td:nth-child(odd) {
        background-color: #e0f7fa;
    }
    .table-responsive-vertical
        .table-hover.table-mc-cyan
        > tbody
        > tr:hover
        > td,
    .table-responsive-vertical .table-hover.table-mc-cyan > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-hover.table-mc-cyan
        > tbody
        > tr
        > td:hover {
        background-color: #b2ebf2;
    }
}
.table-striped.table-mc-teal > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-teal > tbody > tr:nth-child(odd) > th {
    background-color: #e0f2f1;
}
.table-hover.table-mc-teal > tbody > tr:hover > td,
.table-hover.table-mc-teal > tbody > tr:hover > th {
    background-color: #b2dfdb;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-teal > tbody > tr > td,
    .table-responsive-vertical
        .table-striped.table-mc-teal
        > tbody
        > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-striped.table-mc-teal
        > tbody
        > tr
        > td:nth-child(odd) {
        background-color: #e0f2f1;
    }
    .table-responsive-vertical
        .table-hover.table-mc-teal
        > tbody
        > tr:hover
        > td,
    .table-responsive-vertical .table-hover.table-mc-teal > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-hover.table-mc-teal
        > tbody
        > tr
        > td:hover {
        background-color: #b2dfdb;
    }
}
.table-striped.table-mc-green > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-green > tbody > tr:nth-child(odd) > th {
    background-color: #d0f8ce;
}
.table-hover.table-mc-green > tbody > tr:hover > td,
.table-hover.table-mc-green > tbody > tr:hover > th {
    background-color: #a3e9a4;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-green > tbody > tr > td,
    .table-responsive-vertical
        .table-striped.table-mc-green
        > tbody
        > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-striped.table-mc-green
        > tbody
        > tr
        > td:nth-child(odd) {
        background-color: #d0f8ce;
    }
    .table-responsive-vertical
        .table-hover.table-mc-green
        > tbody
        > tr:hover
        > td,
    .table-responsive-vertical .table-hover.table-mc-green > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-hover.table-mc-green
        > tbody
        > tr
        > td:hover {
        background-color: #a3e9a4;
    }
}
.table-striped.table-mc-light-green > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-light-green > tbody > tr:nth-child(odd) > th {
    background-color: #f1f8e9;
}
.table-hover.table-mc-light-green > tbody > tr:hover > td,
.table-hover.table-mc-light-green > tbody > tr:hover > th {
    background-color: #dcedc8;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical
        .table-striped.table-mc-light-green
        > tbody
        > tr
        > td,
    .table-responsive-vertical
        .table-striped.table-mc-light-green
        > tbody
        > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-striped.table-mc-light-green
        > tbody
        > tr
        > td:nth-child(odd) {
        background-color: #f1f8e9;
    }
    .table-responsive-vertical
        .table-hover.table-mc-light-green
        > tbody
        > tr:hover
        > td,
    .table-responsive-vertical
        .table-hover.table-mc-light-green
        > tbody
        > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-hover.table-mc-light-green
        > tbody
        > tr
        > td:hover {
        background-color: #dcedc8;
    }
}
.table-striped.table-mc-lime > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-lime > tbody > tr:nth-child(odd) > th {
    background-color: #f9fbe7;
}
.table-hover.table-mc-lime > tbody > tr:hover > td,
.table-hover.table-mc-lime > tbody > tr:hover > th {
    background-color: #f0f4c3;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-lime > tbody > tr > td,
    .table-responsive-vertical
        .table-striped.table-mc-lime
        > tbody
        > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-striped.table-mc-lime
        > tbody
        > tr
        > td:nth-child(odd) {
        background-color: #f9fbe7;
    }
    .table-responsive-vertical
        .table-hover.table-mc-lime
        > tbody
        > tr:hover
        > td,
    .table-responsive-vertical .table-hover.table-mc-lime > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-hover.table-mc-lime
        > tbody
        > tr
        > td:hover {
        background-color: #f0f4c3;
    }
}
.table-striped.table-mc-yellow > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-yellow > tbody > tr:nth-child(odd) > th {
    background-color: #fffde7;
}
.table-hover.table-mc-yellow > tbody > tr:hover > td,
.table-hover.table-mc-yellow > tbody > tr:hover > th {
    background-color: #fff9c4;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-yellow > tbody > tr > td,
    .table-responsive-vertical
        .table-striped.table-mc-yellow
        > tbody
        > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-striped.table-mc-yellow
        > tbody
        > tr
        > td:nth-child(odd) {
        background-color: #fffde7;
    }
    .table-responsive-vertical
        .table-hover.table-mc-yellow
        > tbody
        > tr:hover
        > td,
    .table-responsive-vertical .table-hover.table-mc-yellow > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-hover.table-mc-yellow
        > tbody
        > tr
        > td:hover {
        background-color: #fff9c4;
    }
}
.table-striped.table-mc-amber > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-amber > tbody > tr:nth-child(odd) > th {
    background-color: #fff8e1;
}
.table-hover.table-mc-amber > tbody > tr:hover > td,
.table-hover.table-mc-amber > tbody > tr:hover > th {
    background-color: #ffecb3;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-amber > tbody > tr > td,
    .table-responsive-vertical
        .table-striped.table-mc-amber
        > tbody
        > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-striped.table-mc-amber
        > tbody
        > tr
        > td:nth-child(odd) {
        background-color: #fff8e1;
    }
    .table-responsive-vertical
        .table-hover.table-mc-amber
        > tbody
        > tr:hover
        > td,
    .table-responsive-vertical .table-hover.table-mc-amber > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-hover.table-mc-amber
        > tbody
        > tr
        > td:hover {
        background-color: #ffecb3;
    }
}
.table-striped.table-mc-orange > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-orange > tbody > tr:nth-child(odd) > th {
    background-color: #fff3e0;
}
.table-hover.table-mc-orange > tbody > tr:hover > td,
.table-hover.table-mc-orange > tbody > tr:hover > th {
    background-color: #ffe0b2;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-orange > tbody > tr > td,
    .table-responsive-vertical
        .table-striped.table-mc-orange
        > tbody
        > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-striped.table-mc-orange
        > tbody
        > tr
        > td:nth-child(odd) {
        background-color: #fff3e0;
    }
    .table-responsive-vertical
        .table-hover.table-mc-orange
        > tbody
        > tr:hover
        > td,
    .table-responsive-vertical .table-hover.table-mc-orange > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-hover.table-mc-orange
        > tbody
        > tr
        > td:hover {
        background-color: #ffe0b2;
    }
}
.table-striped.table-mc-deep-orange > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-deep-orange > tbody > tr:nth-child(odd) > th {
    background-color: #fbe9e7;
}
.table-hover.table-mc-deep-orange > tbody > tr:hover > td,
.table-hover.table-mc-deep-orange > tbody > tr:hover > th {
    background-color: #ffccbc;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical
        .table-striped.table-mc-deep-orange
        > tbody
        > tr
        > td,
    .table-responsive-vertical
        .table-striped.table-mc-deep-orange
        > tbody
        > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-striped.table-mc-deep-orange
        > tbody
        > tr
        > td:nth-child(odd) {
        background-color: #fbe9e7;
    }
    .table-responsive-vertical
        .table-hover.table-mc-deep-orange
        > tbody
        > tr:hover
        > td,
    .table-responsive-vertical
        .table-hover.table-mc-deep-orange
        > tbody
        > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical
        .table-hover.table-mc-deep-orange
        > tbody
        > tr
        > td:hover {
        background-color: #ffccbc;
    }
}
